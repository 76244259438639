import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper" className='text-center'>
            <img className='w-50' src="https://i.imgur.com/qIufhof.png" />
            <div id="info" className='text-center'>
                <h3 className='text-danger'>This page could not be found</h3>
            </div>
        </div >
    )
}

export default PageNotFound
import React, { useContext } from "react";
import ReadMoreReact from "read-more-react";
import { Markup } from "interweave";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

const NewsCard = (props) => {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const navigate = useNavigate();

  const timeString = (time) => {
    let d = new Date(time);
    var value = d.toLocaleString();
    return value;
  };
  function newsHandler(data) {
    navigate(`/fullnews/${data.id}`);
  }
  let myText = props.details.content.slice(0, 180).concat("...");
  let ui = null;
  if (props.details.template === "left") {
    ui = (
      <>
        <div
          className="news p-2 p-md-1 bg-white border-rounded mb-3"
          key={props.details.id}
        >
          <div className="d-flex w-100 flex-column">
            <h3
              className="px-lg-2"
              style={{
                overflowWrap: "break-word",
              }}
            >
              {props.details.title}
            </h3>
            {/* <p>(props.details.id)</p> */}
            <small className="text-right px-2 pt-lg-2 text-secondary">
              Posted on {timeString(props.details.id)}
              {/* 21st Dec 2021, 9:45PM */}
            </small>
            <hr className="w-100 mt-1 mb-2" />
            <div className="row w-100 m-0">
              <div className="col-sm-6 p-1">
                <div className="news-image w-100 text-center">
                  <img src={props.details.headimage} alt="title" />
                </div>
              </div>

              <div className="col-sm-6 p-1">
                <div className="news-content w-100">
                  <div className="text-justify">
                    <ReadMoreReact
                      text={myText}
                      min={100}
                      ideal={150}
                      max={200}
                      readMoreText=". . .Read Full News"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (props.details.template === "right") {
    ui = (
      <>
        <div
          className="news p-2 p-md-1 bg-white border-rounded mb-3"
          key={props.details.id}
        >
          <div className="d-flex w-100 flex-column">
            <h3 className="px-lg-2">{props.details.title}</h3>
            <small className="text-right px-2 pt-lg-2 text-secondary">
              Posted on {timeString(props.details.id)}
              {/* 21st Dec 2021, 9:45PM */}
            </small>
            <hr className="w-100 mt-1 mb-2" />
            <div className="row w-100 m-0">
              <div className="col-sm-6 order-sm-6 p-1">
                <div className="news-image w-100 text-center">
                  <img src={props.details.headimage} alt="title" />
                </div>
              </div>
              <div className="col-sm-6 p-1">
                <div className="news-content w-100">
                  <div className="text-justify">
                    <ReadMoreReact
                      text={myText}
                      min={100}
                      ideal={150}
                      max={200}
                      readMoreText=". . .Read Full News"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    ui = (
      <div
        className="news p-2 p-md-1 bg-white border-rounded mb-3"
        key={props.details.id}
      >
        <div className="d-flex w-100 flex-column">
          <h3 className="px-lg-2">{props.details.title}</h3>
          <small className="text-right px-2 pt-lg-2 text-secondary">
            Posted on {timeString(props.details.id)}
            {/* 21st Dec 2021, 9:45PM */}
          </small>
          <hr className="w-100 mt-1 mb-2" />
          <div className="news-image w-100 text-center">
            <img src={props.details.headimage} alt={props.details.title} />
          </div>

          <div className="news-content w-100">
            <div className="text-justify p-2 p-md-3">
              {/* <ReadMoreReact
                text={myText}
                min={100}
                ideal={150}
                max={200}
                readMoreText=". . .Read Full News"
              /> */}
              <Markup className="d-inline" content={myText} />
              {/* <p className="d-inline">{myText.slice(0, 180).concat("...")}</p> */}
              <div className="d-flex justify-content-between">
                <p
                  onClick={() => {
                    newsHandler(props.details);
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-danger d-inline"
                >
                  Read Full News{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return ui;
};

export default NewsCard;

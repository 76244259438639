import {
  collection,
  getDoc,
  setDoc,
  getDocs,
  doc,
  updateDoc,
  deleteField,
  FieldValue,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { data } from "jquery";
import NewsContext from "../../Context/NewsContext";
import SpinLoader from "../Loader/spinLoader";
import { Pagination } from "@mui/material";
import { paginator } from "../../helpers/helperFunctions";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeletePopup = (props) => {
  const { onClickHandler, id, onSubmitHandler } = props;

  return (
    <>
      <div class="backdrop " id="exampleModal1" style={{ zIndex: "8000" }}>
        <div class=" modalBox modal-dialog col-md-4 col-lg-4" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                News
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={onClickHandler}>
                  &times;
                </span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onSubmitHandler(id);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={onClickHandler}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ViewComments = (props) => {
  const { onClickHandler, data } = props;
  const context = useContext(NewsContext);
  const [comments, setComments] = useState([]);
  const [deleteState, setDeleteState] = useState(false);
  const [commentId, setCommentId] = useState(commentId);

  useEffect(() => {
    context.getSingleNewsComment(data?.id?.toString());
  }, []);

  useEffect(() => {
    setComments(context.singleNewsComment?.comments);
  }, [context.singleNewsComment]);

  return (
    <>
      {deleteState && (
        <DeletePopup
          id={commentId}
          onClickHandler={() => setDeleteState(false)}
          onSubmitHandler={(id) => {
            // context.removeNews(id);
            context.setLoading(true);
            let tempArr = comments.filter((x) => x.id !== id);
            console.log(tempArr, "test");
            updateDoc(doc(db, "comments", data.id.toString()), {
              id: data.id,
              comments: tempArr,
            })
              .then(() => {
                //   // navigate to home page
                alert("comment removed");

                context.getSingleNewsComment(data.id.toString());
                context.setLoading(false);
                setDeleteState(false);
              })
              .catch(() => {
                context.setLoading(false);
              });
          }}
        />
      )}
      <div class="backdrop " id="exampleModal1">
        <div class=" modalBox modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {data.title}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={onClickHandler}>
                  &times;
                </span>
              </button>
            </div>
            <div
              class="modal-body"
              style={{
                minHeight: "40vh",
                maxHeight: "40vh",
                overflowY: "auto",
              }}
            >
              <p className="text-start font-weight-bold">
                Comments ({comments?.length ? comments?.length : 0}){" "}
              </p>
              {comments?.length === 0 && <p>No Comments</p>}
              <List>
                {comments?.map((comment, index) => (
                  <ListItem key={comment?.id}>
                    <ListItemText
                      primary={comment.author + `${index + 1}`}
                      secondary={comment.content}
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setDeleteState(true);
                        setCommentId(comment?.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClickHandler();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Index = (props) => {
  const context = useContext(NewsContext);
  const [id, setId] = useState(id);
  const [holiday, setHoliday] = useState(null);

  // Pagination
  const [posts, SetPosts] = useState([]);
  const [currentNews, setCurrentNews] = useState({});
  const [commentState, setCommentState] = useState(false);
  const [modalstate, setModalstate] = useState(false);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(paginator(posts, value, 10).page);
  };

  // console.log(numOfPages);

  // 1644398379474
  const timeString = (time) => {
    let d = new Date(time);
    var value = d.toLocaleDateString();
    return value;
  };

  // Function to handle input change
  const handleInputSearch = (e) => {
    let val = e.target.value;
    let filteredItem = e.target.value
      ? context.news.filter((person) =>
          person.title.toLowerCase().includes(val.trim().toLowerCase())
        )
      : context.news;

    SetPosts(filteredItem);
  };

  //deleteId is the id from the post you want to delete

  async function getHoliday() {
    const docRef = doc(db, "holidays", "zSrYWeZ5sMAxr4i4DIAk");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setHoliday(docSnap.data());
    } else {
      console.log("No Such Document");
    }
  }

  function deleteHandler(data) {
    setId(data);
    console.log(id);
    setModalstate(true);
  }

  function removehol(e) {
    console.log(e, "data");
    updateDoc(doc(db, "holidays", "zSrYWeZ5sMAxr4i4DIAk"), {
      holidays: holiday.holidays.filter((x) => x.id !== e),
    })
      .then(() => {
        alert("Holiday was removed");
      })
      .then(window.location.reload(false));
  }

  useEffect(() => {
    getHoliday();
  }, []);

  useEffect(() => {
    SetPosts(context.news);
  }, [context.news]);

  console.log(context?.news);

  return (
    <>
      {context.loading && <SpinLoader open={context.loading} />}
      <div className="text-center">
        <Link
          to="/admin/post"
          className="btn btn-danger px-4 my-4  rounded"
          style={{ fontSize: "20px" }}
        >
          Post
        </Link>
      </div>

      <div className="News-list">
        <div className="p-3">
          <h2 className="Listhead text-center  p-2">News Lists</h2>
          <div className="d-flex justify-content-end my-2">
            <div>
              <input
                className="form-control"
                placeholder="Search News"
                id="search"
                onChange={handleInputSearch}
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table  table-bordered">
              <thead className="">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Title</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {paginator(posts, page, 10)?.data?.map((data, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td>{timeString(data.id)}</td>
                      <td>{data.title}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-outline-dark m-2"
                          onClick={() => {
                            setCurrentNews(data);
                            setCommentState(true);
                          }}
                        >
                          View Comments
                        </button>
                        <button type="button" class="btn btn-outline-dark m-2">
                          Edit
                        </button>
                        <button
                          type="button"
                          onClick={() => deleteHandler(data.id)}
                          class="btn btn-outline-dark m-2"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>

            {commentState && (
              <ViewComments
                data={currentNews}
                onClickHandler={() => setCommentState(false)}
              />
            )}
            {modalstate && (
              <DeletePopup
                id={id}
                onClickHandler={() => setModalstate(false)}
                onSubmitHandler={(id) => {
                  context.removeNews(id);
                  setModalstate(false);
                }}
              />
            )}

            <div className="row m-0   justify-content-end align-items-center">
              <Pagination
                count={Math.ceil(posts?.length / 10)}
                page={page}
                onChange={handleChange}
                sx={{
                  "&.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "red", // Change the background color
                  },
                }}
              />
            </div>
          </div>
          <h2 className="Listhead text-center  p-2">Holiday Lists</h2>
          <div class="table-responsive">
            <table class="table  table-bordered">
              <thead className="">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Title</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              {holiday !== null &&
                holiday.holidays.length !== 0 &&
                holiday.holidays.map((data) => {
                  return (
                    <tbody key={data.id}>
                      <tr>
                        <td>{timeString(data.id)}</td>
                        <td>{data.title}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-outline-dark m-2"
                          >
                            Edit
                          </button>
                          <button
                            key={data.id}
                            type="button"
                            class="btn btn-outline-dark m-2"
                            data-toggle="modal"
                            data-target="#exampleModal2"
                          >
                            Delete
                          </button>
                          <div
                            class="modal fade"
                            id="exampleModal2"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Holiday
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are sure you want to delete</p>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={(e) => removehol(data.id)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

export function dateStringConvert(docId) {
  let data = new Date(docId);
  var value = data.toLocaleDateString();
  let date = new Date(value);
  let year = date.getFullYear();
  let mm = date.getMonth() + 1;
  let m = mm.toString();
  let month = m.length === 2 ? m : "0" + m;
  let dd = date.getDate();
  let d = dd.toString();
  let day = d.length === 2 ? d : "0" + d;
  let dateString = year + "." + month + "." + day; // 4.1.2021
  return dateString;
}

export function paginator(items, current_page, per_page_items) {
  let page = current_page || 1,
    per_page = per_page_items,
    offset = (page - 1) * per_page,
    paginatedItems = items?.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items?.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items?.length,
    total_pages: total_pages,
    data: paginatedItems,
  };
}

import "./styles.css";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import News from "./Components/News/News";
import Admin from "./Pages/Admin";
import Index from "./Components/Admin/Index";
import Create from "./Components/Admin/Create";
import PageNotFound from "./Ui/PageNotFound";
import { Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";

import Monnews from "./Pages/Previousnews";
import Previousnews from "./Pages/Previousnews";
import NewsContext from "./Context/NewsContext";
import { FullNews } from "./Components/News/FullNews";
import Edit from "./Components/Admin/Create";

export default function App() {
  const ctx = useContext(NewsContext);

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/admin"
          element={ctx.isLoggedIn ? <Admin /> : <Navigate to={`/login`} />}
        >
          <Route exact path="post" element={<Create />}></Route>
          <Route exact path="edit" element={<Edit />}></Route>
          <Route index element={<Index />}></Route>
        </Route>
        <Route
          exact
          path="/login"
          element={ctx.isLoggedIn ? <Navigate to={`/admin`} /> : <Login />}
        />
        <Route exact path="/" element={<Home />}>
          <Route exact path="previous" element={<Previousnews />}></Route>
          <Route exact path="fullnews/:id" element={<FullNews />}></Route>
          <Route exact index element={<News />}></Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

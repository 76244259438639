import React from "react";

import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import firebaseApp, { db, storage } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  arrayUnion,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import { DefaultEditor } from "react-simple-wysiwyg";
import SpinLoader from "../Loader/spinLoader";
import NewsContext from "../../Context/NewsContext";
const Create = () => {
  const context = useContext(NewsContext);
  const [title, setTitle] = useState(null);
  const [posttype, setPosttype] = useState(null);
  const [content, setContent] = useState(null);
  const [addimage, setAddimage] = useState([]);
  const [temptype, setTemptype] = useState(null);
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState();
  const [imgError, setImgError] = useState(null);
  const [loader, SetLoader] = useState(false);

  // SetLoader(true);
  const additionalUpload = (e) => {
    for (let i = 0; i < e.target.value.length; i++) {
      const newimage = e.target.files[i];
      setAddimage((prevState) => [...prevState, newimage]);
    }
  };

  const handleUpload = (e) => {
    setImgError(null);

    let selected = e.target.files[0];

    if (!selected) {
      setImgError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      setImgError("Please select image file");
      return;
    }
    setImgError(null);
    setImg(selected);
  };
  const storage = getStorage();
  const colRef = collection(db, "campuzonenews");

  function submitHandler(e) {
    context.setLoading(true);
    if (title === "" || posttype === "" || content === "" || temptype === "") {
      alert("Fields are Required");
      context.setLoading(false);
    } else {
      SetLoader(true);
      e.preventDefault();
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let date = new Date();
      let year = date.getFullYear();
      let mm = date.getMonth() + 1;
      let m = mm.toString();
      let month = m.length === 2 ? m : "0" + m;
      let dd = date.getDate();
      let d = dd.toString();
      let day = d.length === 2 ? d : "0" + d;
      let dateString = year + "." + month + "." + day; // 4.1.2021, <10 -> "0" + dayer
      // setId(unique);
      let unique = date.getTime();

      let monthvalue = months[mm - 1];
      let monthData = monthvalue + year;

      const uploadPath = `images/${unique}`; // geting the image path

      const storageRef = ref(storage, uploadPath); // getting the storageRef

      uploadBytes(storageRef, img).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setImgUrl(url);
          let newContent = {
            title: title,
            posttype: posttype,
            id: unique,
            month: monthData,
            content: content.toString(),
            headimage: url,
            addimage: addimage,
            template: temptype,
          };
          console.log(posttype);
          if (posttype === "Hol") {
            console.log("holiday");
            //
            updateDoc(doc(db, "holidays", "zSrYWeZ5sMAxr4i4DIAk"), {
              holidays: arrayUnion(newContent),
            })
              .then(() => {
                console.log("news added!!!");
                //   // navigate to home page
                alert("Updated in holidays!!!");
                context.setLoading(false);
                navigate("/admin");
              })
              .catch((e) => {
                console.log(e, "Update Failed");
              });
          } else {
            updateDoc(doc(db, "campuzonenews", dateString), {
              news: arrayUnion(newContent),
            })
              .then(() => {
                console.log("news added!!!");
                //   // navigate to home page
                alert("Updated in db!!!");
                context.setLoading(false);
                navigate("/admin");
              })
              .catch((e) => {
                // console.log("code", e.code);
                // console.log("message", e.message);
                // console.log("e", e);
                //FirebaseError: No document to update: projects/campuzone-news/databases/(default)/documents/campuzonenews/7.1.2022
                if (
                  e.message.includes("No document to update") ||
                  e.code.includes("not-found")
                ) {
                  setDoc(doc(db, "campuzonenews", dateString), {
                    news: [newContent],
                  })
                    .then(() => {
                      console.log("first news of the day added!!!");
                      //   // navigate to home page
                      alert("Updated in db!!!");
                      context.setLoading(false);
                      navigate("/admin");
                    })
                    .catch((e) => console.log(e));
                } else {
                  console.log(e);
                }
              });
          }
        });
      });
    }
  }
  // // after
  return (
    <>
      {context.loading && <SpinLoader open={context.loading} />}
      <div className="uploaddata">
        <div className="container shadow bg-white rounded col-lg-6 my-4  py-2">
          <h2 className=" py-2 text-danger text-center rounded my-2 ">
            Upload a Post
          </h2>
          {/* <form onSubmit={handleSubmit}> */}
          <form onSubmit={submitHandler}>
            <label htmlFor="basic-url" className="form-label">
              Title
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                value={title}
                required="required"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <label htmlFor="basic-url" className="form-label">
              Select the Post Type
            </label>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                value={posttype}
                required="required"
                onChange={(e) => setPosttype(e.target.value)}
              >
                <option>Choose...</option>
                <option value="news">News</option>
                <option value="article">Article</option>
                <option value="gov">goverment Regulations</option>
                <option value="Hol">Holiday</option>
              </select>
            </div>

            <div className="input-group mb-3 ">
              <label htmlFor="basic-url" className="form-label">
                Upload a Photo
              </label>
              <input
                type="file"
                className="form-control w-100"
                id="inputGroupFile02"
                required="required"
                onChange={handleUpload}
                // value={headimage}
                // onChange={(e) => setHeadimage(e.target.value[0])}
              />
            </div>
            <p>{imgError}</p>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Contents
              </label>
              {/* <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                required="required"
                value={content}
              ></textarea> */}
            </div>
            <DefaultEditor
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <label htmlFor="files">Additional images (upload 1 OR 2 )</label>
            <input
              type="file"
              className="form-control w-100"
              id="inputGroupFile02"
              multiple
              onChange={additionalUpload}
            />
            <label htmlFor="basic-url" className="form-label">
              Select the Template Type
            </label>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                value={temptype}
                required="required"
                onChange={(e) => setTemptype(e.target.value)}
              >
                <option>Choose...</option>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-danger my-2">
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Create;

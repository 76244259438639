import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { dateStringConvert } from "../helpers/helperFunctions";

const NewsContext = React.createContext({
  prev: {},
  news: [],
  tempNews: [],
  holidays: [],
  previousNews: [],
  singleNewsComment: {},
  getPrevious: () => {},
  setPrevious: () => {},
  setUserUid: () => {},
  removeNews: () => {},
  getSingleNewsComment: () => {},
  userUid: "",
  setIsLoggedIn: () => {},
  isLoggedIn: Boolean,
  setLoading: () => {},
  loading: Boolean,
});

export const NewsContextProvider = (props) => {
  let navigate = useNavigate();
  const [prev, setPrev] = useState(null);
  const [userUid, setUserUid] = useState(
    localStorage.getItem("campuzonenewsAdminUId")
  );
  const [isLoggedIn, setIsLoggedIn] = useState(userUid ? true : false);
  const [news, setNews] = useState([]);

  const [tempNews, setTempNews] = useState([]);
  const [holidays, setHoliday] = useState(null);
  const [previousNews, setPreviousNews] = useState([]);
  const [singleNewsComment, setSingleNewsComment] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, "campuzonenews"));
    let tempArr = [];
    querySnapshot.forEach((docs) => {
      tempArr.push(...docs.data().news);
      setNews(tempArr);
      setTempNews(tempArr?.filter((x) => x.posttype === "news").reverse());
    });
    setLoading(false);
  }

  async function getHoliday() {
    setLoading(true);
    const docRef = doc(db, "holidays", "zSrYWeZ5sMAxr4i4DIAk");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setLoading(false);
      setHoliday(docSnap.data());
    } else {
      setLoading(false);
      console.log("No Such Document");
    }
  }

  function getPrevious(mon = 0, year = 0) {
    let month = mon + year;
    let tempArr = tempNews.filter((x) => x.month === month);
    setPreviousNews(tempArr);
  }

  function setPrevious(mon, year) {
    setPrev((prevState) => {
      return {
        ...prevState,
        month: mon,
        year: year,
      };
    });
  }
  async function removeNews(docId) {
    setLoading(true);
    let dateString = dateStringConvert(docId);

    const docRef = doc(db, "campuzonenews", dateString);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      var tempNews = docSnap.data().news;
    } else {
      console.log("No Such Document");
      setLoading(false);
    }
    var newsArr = tempNews.filter((x) => x.id !== docId);
    updateDoc(doc(db, "campuzonenews", dateString), {
      news: newsArr,
    }).then(() => {
      alert("News Removed successfully");
      getData();
      setLoading(false);
      deleteDoc(doc(db, "comments", docId?.toString()));
    });
  }

  async function getSingleNewsComment(docId) {
    console.log(docId, "tests");
    setLoading(true);
    const docRef = doc(db, "comments", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setLoading(false);
      setSingleNewsComment(docSnap.data());
    } else {
      setLoading(false);
      console.log("No Such Document");
      setSingleNewsComment({});
    }
  }

  useEffect(() => {
    getData();
    getHoliday();
  }, []);

  return (
    <NewsContext.Provider
      value={{
        prev: prev,
        news: news,
        tempNews: tempNews,
        holidays: holidays,
        previousNews: previousNews,
        singleNewsComment: singleNewsComment,
        getPrevious: getPrevious,
        setPrevious: setPrevious,
        removeNews: removeNews,
        getSingleNewsComment: getSingleNewsComment,
        setUserUid: setUserUid,
        userUid: userUid,
        setIsLoggedIn: setIsLoggedIn,
        isLoggedIn: isLoggedIn,
        loading: loading,
        setLoading: setLoading,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsContext;

import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NewsContext from "../../Context/NewsContext";
import Logo from "../../Assests/img/logo2.png";

const Navbar = () => {
  let location = useLocation();
  const ctx = useContext(NewsContext);
  const navigate = useNavigate();
  let LogoutUi = (
    <>
      <li class="nav-item">
        <span onClick={() => handleLogout()} className="nav-link" role="button">
          Logout
        </span>
      </li>
    </>
  );

  const handleLogout = () => {
    ctx.setIsLoggedIn(false);
    ctx.setUserUid("");
    localStorage.clear();
    navigate("/login");
  };
  return (
    <nav className="navbar navbar-expand-md">
      {/* <!-- Brand --> */}
      <Link className="navbar-brand d-flex row align-items-center" to={`/`}>
        <img className="bg-light" src={Logo} alt="logo" />
        <span>Campuzone News</span>
      </Link>

      {/* <!-- Toggler/collapsibe Button --> */}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
        style={{ outline: "0" }}
      >
        <span className="fa fa-bars text-light"></span>
      </button>

      {/* <!-- Navbar links --> */}
      <div className="collapse navbar-collapse" id="collapsibleNavbar">
        <ul className="navbar-nav w-100 justify-content-end">
          <li className="nav-item">
            <a className="nav-link" href="https://campuzone.com/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://campuzone.com/about.html">
              About
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://campuzone.com/contact.html">
              Contact Us
            </a>
          </li>

          {location.pathname === "/admin" && LogoutUi}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

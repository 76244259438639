import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyD44sQPg6NHOEgo4GpZPXDgEFurq3s5Zws",
  authDomain: "campuzone-news.firebaseapp.com",
  projectId: "campuzone-news",
  storageBucket: "campuzone-news.appspot.com",
  messagingSenderId: "942012145113",
  appId: "1:942012145113:web:79bab31a16d4e19cac8dc8",
  measurementId: "G-Y5Z89RXZ0Q",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDx4lC0WpJDUE58Od-LK-CouBxis_O-v4A",
//   authDomain: "cz-news.firebaseapp.com",
//   projectId: "cz-news",
//   storageBucket: "cz-news.appspot.com",
//   messagingSenderId: "898685654369",
//   appId: "1:898685654369:web:e0041102460b2674da2120",
//   measurementId: "G-VVPLXQHGLY",
// };
// Initialize Firebasec
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export default firebaseApp;
export { auth, db, analytics, storage };
// sign up
export function signup(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}
// Login in
export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}
// Login out
// export function logout() {
//   return signOut(auth);
// }
export function useAuth() {
  const [currentUser, setcurrentUser] = useState();
  return currentUser;
}

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NewsContext from "../Context/NewsContext";

const Previous = (props) => {
  const context = useContext(NewsContext);
  let navigate = useNavigate();
  let ui = null;
  if (context.previousNews.length === 0) {
    ui = <p className="py-1 text-center">No news !</p>;
  } else {
    ui = (
      <>
        {context.previousNews.slice(0, 3).map((data) => {
          return (
            <div
              className="row p-2 mb-2 align-items-center"
              key={data.id}
              style={{ boxShadow: "0px 3px 6px #00000036" }}
            >
              <div className="col-lg-3">
                <img src={data.headimage} alt="news" />
              </div>
              <div className="col-lg-9 py-1 pl-2">
                <small className="title text-dark font-weight-bold">
                  {data.title}
                </small>
              </div>
            </div>
          );
        })}
        <button
          type="submit"
          onClick={() => navigate("/previous")}
          className="btn w-100 btn-info py-1"
        >
          View More
        </button>
      </>
    );
  }
  return (
    <div className="p-2 bg-light">
      <p
        className="text-right text-danger mb-2"
        style={{ borderBottom: "1px solid" }}
      >
        News from {props.spot.month} {props.spot.year}
      </p>
      <div className="news">{ui}</div>
    </div>
  );
};

export default Previous;

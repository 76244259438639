import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NewsContext from "../Context/NewsContext";

const Calendar = (props) => {
  const context = useContext(NewsContext);
  let navigate = useNavigate();
  const goTo = (mon, year) => {
    context.setPrevious(mon, year);
    context.getPrevious(mon, year);
    navigate("/previous");
  };
  return (
    <div className="row mt-2 rounded  ">
      {props.arr.map((data, index) => {
        return (
          <div
            className="col-lg-3 py-3 shadow calender from-left rounded "
            key={index}
            onClick={() => goTo(data.mapMonth, data.mapYear)}
          >
            <div className="row flex-column   text-center cursor-pointer ">
              <h5 className="bg-danger box rounded-bottom">{data.mapMonth}</h5>
              <p className="text-dark-50">{data.mapYear}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Calendar;

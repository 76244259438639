import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import NewsContext from "../../Context/NewsContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RWebShare } from "react-web-share";
import { Markup } from "interweave";

import Box from "@mui/material/Box";
import CommentSection from "../../Pages/CommentSection";

export const FullNews = () => {
  const params = useParams();
  const [data, setData] = useState({
    title: "",
    content: "",
    id: "",
    headimage: "",
  });
  const timeString = (time) => {
    let d = new Date(time);
    var value = d.toLocaleString();
    return value;
  };

  const context = useContext(NewsContext);

  useEffect(() => {
    setData(context.news.find((x) => x.id == params.id));
  }, [context.news, context]);

  return (
    <>
      <div className="p-3">
        <div
          className="news p-2 p-md-1 container bg-white border-rounded mb-3"
          key={data?.id}
        >
          <Link className="btn btn-danger m-2" to={"/"}>
            <i className="fas fa-arrow-circle-left mx-1"></i> Go Back
          </Link>

          {data === undefined ? (
            <>
              <div className="d-flex w-100 flex-column">
                <Skeleton count={1} />

                <small className="text-right c px-2 pt-lg-2 text-secondary">
                  <Skeleton count={1} className="w-25" />
                  {/* 21st Dec 2021, 9:45PM */}
                </small>
                <hr className="w-100 mt-1 mb-2" />

                <div className="mx-auto col-lg-6 col-md-8">
                  <Skeleton count={1} style={{ height: "500px" }} />
                </div>

                <div className="news-content w-100">
                  <div className="text-justify p-2 p-md-3">
                    <Skeleton count={6} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex w-100 flex-column">
                <h3 className="px-lg-2">{data?.title}</h3>

                <small className="text-right px-2 pt-lg-2 text-secondary">
                  Posted on {timeString(data?.id)}
                  {/* 21st Dec 2021, 9:45PM */}
                </small>
                <hr className="w-100 mt-1 mb-2" />
                <div className="news-image w-100 text-center">
                  <img src={data?.headimage} alt={data?.title} />
                </div>

                <div className="d-flex justify-content-start  mt-2">
                  <FacebookShareButton
                    url={"https://news.campuzone.com/fullnews/" + data?.id}
                    hashtag={"#campuzonenews"}
                    className="mx-2 socialshare"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={"https://news.campuzone.com/fullnews/" + data?.id}
                    className="mx-2 socialshare"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={"https://news.campuzone.com/fullnews/" + data?.id}
                    title={data?.title}
                    summary={data?.title}
                    source={"campuzone-sundar"}
                    hashtag={"#campuzonenews"}
                    image={data?.headimage}
                    className="mx-2 socialshare"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={"http://news.campuzone.com/fullnews/" + data?.id}
                    title={data?.title}
                    className="mx-2 socialshare"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>

                <div className="news-content w-100">
                  <div className="text-justify p-2 p-md-3">
                    <Markup content={data?.content} />
                  </div>
                </div>
              </div>
              <Box className="p-2">
                <CommentSection />
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import NewsContext from "../../Context/NewsContext";

const Prenews = () => {
  const [newsData, setNewsData] = React.useState({
    title: "",
    headimage: "",
    content: "",
  });
  const timeString = (time) => {
    let d = new Date(time);
    var value = d.toLocaleString();
    return value;
  };

  const context = useContext(NewsContext);

  if (context.prev === null) {
    return <Navigate to="/" />;
  }

  if (context.previousNews.length === 0) {
    return (
      <>
        <h4 className="pt-4">
          <Link className="btn btn-danger mx-1" to={"/"}>
            <i className="fas fa-arrow-circle-left mx-1"></i> Go Back
          </Link>
          OOPS no News to Show
        </h4>
        <div className="text-center mx-5 mt-3 mb-5">
          <img className="w-50" src="/images/nodata.gif" />
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="my-2 p-2 bg-white">
        <Link
          to="/"
          className="text-danger mx-1 fas fa-arrow-circle-left text-decoration-none"
          role="button"
        ></Link>
        News From {context.prev.month} {context.prev.year}
      </h2>
      <div className="card-columns ">
        {context.previousNews.map((data) => (
          <div key={data.id} class="card ">
            <img class="card-img-top" src={data.headimage} alt="header image" />
            <div class="card-body">
              <h5 class="card-title">
                <b>{data.title}</b>
              </h5>
              <button
                class="btn btn-danger"
                data-toggle="modal"
                data-target="#exampleModalLong"
                onClick={() => setNewsData(data)}
              >
                View Full News
              </button>
              <div
                class="modal fade"
                id="exampleModalLong"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog w-100" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5
                        class="modal-title"
                        id="exampleModalLongTitle"
                        style={{ color: "red" }}
                      >
                        <b>{newsData.title}</b>
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <img
                        class="card-img-top"
                        src={newsData.headimage}
                        alt="Card image cap"
                      />
                      <p>{newsData.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Prenews;

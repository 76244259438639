import React from "react";
import Prenews from "../Components/News/Prenews";
import Monthnews from "../Components/News/Prenews";

const Previousnews = () => {
  return (
    <>
      <div className="container py-2 px-1">
        <Prenews />
        {/* <div className="col-md-9 col-lg-9 py-2 px-1">
        </div>
        <div className="col-md-3 col-lg-3 p-2 d-none d-md-block"></div> */}
      </div>
    </>
  );
};

export default Previousnews;

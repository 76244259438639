import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { NewsContextProvider } from "./Context/NewsContext";

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <NewsContextProvider>
      <App />
    </NewsContextProvider>
  </BrowserRouter>,
  rootElement
);

import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import { Button, List, ListItem, ListItemText, TextField } from "@mui/material";
import { Backdrop } from "@mui/material";
import NewsContext from "../Context/NewsContext";
import { db } from "../firebase";

import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import SpinLoader from "../Components/Loader/spinLoader";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const AddComment = (props) => {
  const { open, onClickHandler } = props;
  const params = useParams();
  const context = useContext(NewsContext);
  const [commentData, setCommentData] = React.useState({
    author: "",
    rating: 0,
    content: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let d = new Date();
    let updatedData = {
      author: commentData.author,
      rating: parseInt(commentData.rating),
      content: commentData.content,
      id: "comment" + d.getTime(),
    };
    context.setLoading(true);
    if (updatedData.author === "" || updatedData?.content == "") {
      alert("Fields are Required");
      context.setLoading(false);
      return;
    }
    updateDoc(doc(db, "comments", params.id), {
      id: params.id,
      comments: arrayUnion(updatedData),
    })
      .then(() => {
        //   // navigate to home page
        alert("comment added");

        context.getSingleNewsComment(params.id);
        context.setLoading(false);
        onClickHandler();
      })
      .catch((e) => {
        if (
          e.message.includes("No document to update") ||
          e.code.includes("not-found")
        ) {
          setDoc(doc(db, "comments", params.id), {
            id: params.id,
            comments: [updatedData],
          })
            .then(() => {
              //   // navigate to home page

              alert("comment added");
              context.getSingleNewsComment(params.id);
              context.setLoading(false);
              onClickHandler();
            })
            .catch((e) => console.log(e));
        } else {
          console.log(e);
        }
      });
  };

  return (
    <>
      {context.loading && <SpinLoader open={context.loading} />}
      <Backdrop sx={{ color: "#fff", zIndex: 99 }} open={open}>
        <div className="col-md-4 bg-white rounded p-2">
          <h3 className="text-start font-weight-bold">Post Comment</h3>
          <TextField
            id="author"
            label="Author"
            variant="outlined"
            fullWidth
            size="small"
            className="my-2"
            value={commentData?.author}
            onChange={(e) => {
              setCommentData({
                ...commentData,
                author: e.target.value,
              });
            }}
          />
          <TextField
            id="content"
            label="Add a comment"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            size="small"
            className="my-2"
            value={commentData?.content}
            onChange={(e) => {
              setCommentData({
                ...commentData,
                content: e.target.value,
              });
            }}
          />
          <p className="text-start font-weight-bold">Rating</p>
          <StyledRating
            className="d-block"
            name="customized-color"
            value={commentData?.rating}
            getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
            precision={0.5}
            onChange={(e) => {
              setCommentData({
                ...commentData,
                rating: e.target.value,
              });
            }}
          />
          <div className="text-center">
            <Button
              className="mt-2 mx-2"
              type="submit"
              sx={{ textTransform: "none" }}
              color="error"
              variant="contained"
              onClick={onClickHandler}
            >
              cancel
            </Button>
            <Button
              className="mt-2"
              type="submit"
              sx={{ textTransform: "none" }}
              color="error"
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Backdrop>
    </>
  );
};

const CommentSection = () => {
  const params = useParams();
  const context = useContext(NewsContext);
  const [comments, setComments] = React.useState({});
  const [addComment, setAddComment] = React.useState(false);

  function averageRating() {
    let rateValue = 0;

    if (comments?.comments?.length > 0) {
      for (let comment of comments?.comments) {
        rateValue += comment.rating;
      }

      const average = rateValue / comments?.comments?.length;
      console.log(Math.round(average), "test");
      return Math.round(average);
    }

    return 0; // Default value if there are no comments
  }

  useEffect(() => {
    context.getSingleNewsComment(params.id);
  }, []);

  useEffect(() => {
    setComments(context.singleNewsComment);
  }, [context.singleNewsComment]);

  return (
    <div>
      {addComment && (
        <AddComment
          open={addComment}
          onClickHandler={() => {
            setAddComment(false);
          }}
        />
      )}
      <p className="text-start font-weight-bold">Rating</p>
      <StyledRating
        name="customized-color"
        value={averageRating()}
        getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
        precision={0.5}
        disabled
      />

      <p className="text-start font-weight-bold">
        Comments ({comments?.comments?.length ? comments?.comments?.length : 0})
      </p>

      <List>
        {comments?.comments?.map((comment, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={comment.author + `${index + 1}`}
              secondary={comment.content}
            />
          </ListItem>
        ))}
      </List>

      <Button
        className="mt-2"
        type="submit"
        sx={{ textTransform: "none" }}
        color="error"
        variant="contained"
        onClick={() => {
          setAddComment(true);
        }}
      >
        Post Comment
      </Button>
    </div>
  );
};

export default CommentSection;

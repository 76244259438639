import React from "react";
import Previous from "../../Ui/Previous";
import Calendar from "../../Ui/Calendar";

const SpotLight = (props) => {
  return (
    <>
      <h6 class="bg-light p-2">Our Previous News</h6>
      <Previous spot={props.spot} />
      {props.arr && <Calendar arr={props.arr} />}
    </>
  );
};

export default SpotLight;

import React, { useEffect, useState, useContext } from "react";
import NewsCard from "./NewsCard";
import SpotLight from "./SpotLight";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import NewsContext from "../../Context/NewsContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const News = () => {
  const context = useContext(NewsContext);
  let date = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [newsType, setNewsType] = useState("news");
  const [arr, setarr] = useState([]);
  const [spot] = useState({
    month: "",
    year: "",
  });
  useEffect(() => {
    setTimeout(() => {
      setSkeleton(false);
    }, 3000);
  }, []);

  useEffect(() => {
    setLoading(true);
    date.setMonth(date.getMonth() - 1);
    spot.month = months[date.getMonth()];
    spot.year = date.getFullYear();

    let emptyarr = [];
    for (var i = 0; i <= 11; i++) {
      date.setMonth(date.getMonth() - 1);
      var info = {
        mapMonth: months[date.getMonth()],
        mapYear: date.getFullYear(),
      };
      emptyarr[i] = info;
      setarr(emptyarr);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    context.getPrevious(spot.month, spot.year);
    context.setPrevious(spot.month, spot.year);
  }, [context.tempNews]);

  // Pagination
  const [posts, SetPosts] = useState([]);
  const [postPerPage, SetPostPerPage] = useState(10);
  const [currentPage, SetCurrentPage] = useState(1);

  useEffect(() => {
    SetPosts(context.tempNews);
  }, [context.tempNews]);
  const [pageItem, SetPageItem] = useState({
    start: 0,
    end: postPerPage,
  });

  const onPageChangeEvent = (start, end) => {
    SetPageItem({
      start: start,
      end: end,
    });
  };

  const OnPerPostChangeEvent = (e) => {
    SetPostPerPage(e.target.value);
    SetCurrentPage(1);
  };

  const numOfPages = Math.ceil(posts.length / postPerPage);
  // console.log(numOfPages);

  const numOfButtons = [];
  for (let i = 1; i <= numOfPages; i++) {
    numOfButtons.push(i);
  }

  const prevPageClick = () => {
    if (currentPage === 1) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const nextPageClick = () => {
    if (currentPage === numOfButtons.length) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    let tempNumberOfButtons = [...arrOfCurrButtons];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (currentPage >= 1 && currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, numOfButtons.length];
    } else if (currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (currentPage > 4 && currentPage < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(currentPage - 2, currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(currentPage, currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numOfButtons.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (currentPage > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (currentPage === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      SetCurrentPage(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentPage === dotsRight) {
      SetCurrentPage(arrOfCurrButtons[3] + 2);
    } else if (currentPage === dotsLeft) {
      SetCurrentPage(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfButtons);
    const value = currentPage * postPerPage;

    onPageChangeEvent(value - postPerPage, value);
  }, [currentPage, postPerPage, numOfPages]);

  $(document).ready(function () {
    $(".filters div p").click(function () {
      $(".filters div p.active").removeClass("active");
      $(this).addClass("active");
    });
  });
  const NoData = () => {
    return (
      <div className="bg-white text-center">
        <img className="w-50" src="/images/nodata.gif" />
      </div>
    );
  };
  const typeHandler = (data) => {
    setNewsType(data);
  };

  let ui = null;
  if (newsType === "holiday") {
    if (context.holidays.holidays.length === 0) {
      ui = (
        <>
          <h4 className="text-danger p-2 text-center text-capitalize">
            No Holidays to show
          </h4>
          <NoData />
        </>
      );
    } else {
      ui = (
        <>
          {context.holidays !== null &&
            context.holidays.holidays.reverse().map((data) => {
              return <NewsCard details={data} key={data.id} />;
            })}
        </>
      );
    }
  } else {
    if (context.news.filter((x) => x.posttype === newsType).length === 0) {
      ui = (
        <>
          <h4 className="text-danger p-2 text-center text-capitalize">
            No {newsType} to show
          </h4>
          <NoData />
        </>
      );
    } else {
      ui = (
        <>
          {newsType !== "news" ? (
            context.news
              .filter((x) => x.posttype === newsType)
              .reverse()
              .map((data) => {
                return <NewsCard details={data} key={data.id} />;
              })
          ) : (
            <>
              {context.tempNews
                .slice(pageItem.start, pageItem.end)
                .map((data) => {
                  return <NewsCard details={data} key={data.id} />;
                })}
              <div className="row m-0  bg-white justify-content-end align-items-center">
                <div
                  className={` btn  text-white px-4  ${
                    currentPage === 1 ? "arrow-disabled" : "buttonnav"
                  }`}
                  onClick={prevPageClick}
                  role="button"
                >
                  <i class="fas fa-angle-double-left"></i>
                </div>
                {arrOfCurrButtons.map((data, index) => (
                  <button
                    key={index}
                    className={` d-sm-flex justify-content-center btn align-items-center d-none  p-2 mx-1 pages
                    ${currentPage === data ? "active" : ""}`}
                    onClick={() => {
                      SetCurrentPage(data), window.scrollTo(0, 0);
                    }}
                  >
                    {data}
                  </button>
                ))}
                <div
                  className={`btn  text-white px-4
                ${
                  currentPage === numOfButtons.length
                    ? "arrow-disabled"
                    : "buttonnav"
                }`}
                  onClick={nextPageClick}
                  role="button"
                >
                  <i class="fas fa-angle-double-right"></i>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
  return (
    <>
      <div className="container p-0 pt-md-1 pb-md-3 px-md-0">
        <div className="filters p-2 pt-md-0 row justify-content-start justify-content-md-end">
          <div>
            <p
              className="active p-2"
              role="button"
              onClick={() => typeHandler("news")}
            >
              News
            </p>
          </div>
          <div className="ml-sm-3">
            <p
              className="p-2 py-md-2 px-md-3"
              role="button"
              onClick={() => typeHandler("article")}
            >
              Articles
            </p>
          </div>
          <div className="ml-sm-3">
            <p
              className="p-2 py-md-2 px-md-3"
              role="button"
              onClick={() => typeHandler("gov")}
            >
              Government Regulations
            </p>
          </div>
          <div className="ml-sm-3">
            <p
              className="p-2 py-md-2 px-md-3"
              role="button"
              onClick={() => typeHandler("holiday")}
            >
              Holidays
            </p>
          </div>
          {/* <div className="ml-3">
            <p className="p-2 py-md-2 px-md-3" role="button">
              Tamil
            </p>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-9 col-lg-8 py-2 px-1">
            {skeleton ? (
              <>
                <div className="mt-3">
                  <Skeleton className="bg-white" />

                  <small className="  px-2 pt-lg-2 text-secondary">
                    <Skeleton count={1} className="w-25 bg-white" />
                    {/* 21st Dec 2021, 9:45PM */}
                  </small>
                  <hr className="w-100 mt-1 mb-2" />

                  <Skeleton className="bg-white" style={{ height: "400px" }} />
                  <Skeleton count={4} className="bg-white" />
                </div>
                <div className="mt-3">
                  <Skeleton className="bg-white" />

                  <small className="  px-2 pt-lg-2 text-secondary">
                    <Skeleton count={1} className="w-25 bg-white" />
                    {/* 21st Dec 2021, 9:45PM */}
                  </small>
                  <hr className="w-100 mt-1 mb-2" />

                  <Skeleton className="bg-white" style={{ height: "400px" }} />
                  <Skeleton count={4} className="bg-white" />
                </div>
              </>
            ) : (
              ui
            )}
          </div>
          <div className="col-md-3 col-lg-4 p-2 d-none d-md-block">
            <SpotLight spot={spot} arr={arr} />
          </div>
        </div>
      </div>
    </>
  );
};

export default News;

import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const SpinLoader = (props) => {
  const { open } = props;
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SpinLoader;
